import React from "react"
import Headroom from "react-headroom"
import MenuItem from "./MenuItem"
import styled from "@emotion/styled"
import { ALAKERTA_GREEN } from "../util/constants"

const MenuItems = styled.div`
  display: flex;
  background: ${ALAKERTA_GREEN};
  align-items: center;
  justify-content: space-evenly;
  height: 3.5rem;
`

const menuItems = [
  {
    name: "Contact",
    to: "/",
    id: "menu_item.contact",
  },
  {
    name: "Events",
    to: "/events/",
    id: "menu_item.events",
  },
  {
    name: "About",
    to: "/about/",
    id: "menu_item.about",
  },
]

const Navbar = () => {
  return (
    <Headroom>
      <MenuItems>
        {menuItems.map(menuItem => (
          <MenuItem
            name={menuItem.name}
            to={menuItem.to}
            key={menuItem.name}
            id={menuItem.id}
          ></MenuItem>
        ))}
      </MenuItems>
    </Headroom>
  )
}

export default Navbar
