import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const SponsorLogosStyle = styled.div`
  flex: 8;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const sponsorLogoLinkStyle = css`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 100%;
  margin: 0.5em;
`

const sponsorLogoImageWrapperStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "60%",
}

const sponsorLogoImageStyle = {}

const SponsorLogos = () => {
  const data = useStaticQuery(graphql`
    query {

      olviLogo: file(relativePath: { eq: "sponsor_logos/olvi_logo.png" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      fMusiikkiLogo: file(
        relativePath: { eq: "sponsor_logos/f_musiikki_logo.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      molotovLogo: file(
        relativePath: { eq: "sponsor_logos/molotov_logo.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      olarinPanimoLogo: file(
        relativePath: { eq: "sponsor_logos/olarin_panimo_logo.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }

      ruokaaOvelleLogo: file(
        relativePath: { eq: "sponsor_logos/ruokaa_ovelle_logo.png" }
      ) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
          }
        }
      }
    }
  `)

  return (
    <SponsorLogosStyle className="sponsor-logos">
      <a
        css={sponsorLogoLinkStyle}
        href="https://www.olvi.fi/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          fluid={data.olviLogo.childImageSharp.fluid}
          alt="Olvi brewery logo"
          style={sponsorLogoImageWrapperStyle}
          imgStyle={sponsorLogoImageStyle}
        />
      </a>
      <a
        css={sponsorLogoLinkStyle}
        href="https://www.f-musiikki.fi/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          fluid={data.fMusiikkiLogo.childImageSharp.fluid}
          alt="F-Musiikki logo"
          style={sponsorLogoImageWrapperStyle}
          imgStyle={sponsorLogoImageStyle}
        />
      </a>
      <a
        css={sponsorLogoLinkStyle}
        href="https://molotowfinland.fi/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          fluid={data.molotovLogo.childImageSharp.fluid}
          alt="Molotov logo"
          style={sponsorLogoImageWrapperStyle}
          imgStyle={sponsorLogoImageStyle}
        />
      </a>
      <a
        css={sponsorLogoLinkStyle}
        href="https://www.olarinpanimo.fi/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          fluid={data.olarinPanimoLogo.childImageSharp.fluid}
          alt="Olarin Panimo logo"
          style={sponsorLogoImageWrapperStyle}
          imgStyle={sponsorLogoImageStyle}
        />
      </a>
      <a
        css={sponsorLogoLinkStyle}
        href="https://ruokaaovelle.com/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <Img
          fluid={data.ruokaaOvelleLogo.childImageSharp.fluid}
          alt="Ruokaa Ovelle logo"
          style={sponsorLogoImageWrapperStyle}
          imgStyle={sponsorLogoImageStyle}
        />
      </a>
    </SponsorLogosStyle>
  )
}

export default SponsorLogos
