import React from "react"
import styled from "@emotion/styled"
import LocaleSelection from "./LocaleSelection"
import SponsorLogos from "./SponsorLogos"
import { ALAKERTA_GREEN, ALAKERTA_BORDER } from "../util/constants"

const FooterStyles = styled.div`
  height: 10rem;
  background: ${ALAKERTA_GREEN};
  display: flex;
  border-top: ${ALAKERTA_BORDER};
`

const Footer = () => {
  return (
    <FooterStyles className="footer">
      <SponsorLogos />
      <LocaleSelection />
    </FooterStyles>
  )
}

export default Footer
