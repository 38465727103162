// css
export const ALAKERTA_GREEN = `#031C09`
export const ALAKERTA_LIGHT_YELLOW = `#FCF7D7`
export const ALAKERTA_RED = `#600008`
export const ALAKERTA_DARKER_RED = `#3D0005`
export const ALAKERTA_DARK_BACKGROUND = `#13181c`
export const ALAKERTA_DARK_YELLOW = `#C39F55`

export const ALAKERTA_FONT = `"Alakerta Part 2 Regular", sans-serif`
export const SECONDARY_FONT = `"Bahnschrift", sans-serif`

export const ALAKERTA_BORDER = `6px groove ${ALAKERTA_DARK_YELLOW}`

export const FINNISH_HOURS_TO_ENGLISH_HOURS = {
  "00": "12 PM",
  "01": "1 AM",
  "02": "2 AM",
  "03": "3 AM",
  "04": "4 AM",
  "05": "5 AM",
  "06": "6 AM",
  "07": "7 AM",
  "08": "8 AM",
  "09": "9 AM",
  "10": "10 AM",
  "11": "11 AM",
  "12": "12 AM",
  "13": "1 PM",
  "14": "2 PM",
  "15": "3 PM",
  "16": "4 PM",
  "17": "5 PM",
  "18": "6 PM",
  "19": "7 PM",
  "20": "8 PM",
  "21": "9 PM",
  "22": "10 PM",
  "23": "11 PM"
}
