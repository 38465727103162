import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { SECONDARY_FONT } from "../util/constants"

import { changeLocale, useIntl } from "gatsby-plugin-intl"

const LocaleSelectionStyles = styled.div`
  height: 100%;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  font-family: ${SECONDARY_FONT};
  margin-right: 1rem;
  flex: 2;
`

const LocaleKeyUpperCased = styled.span`
  text-transform: uppercase;
`

const LocaleOptionStyle = css`
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  margin: 0.5rem;
  padding: 0 1rem;
  color: white;
  z-index: 1;
`

const LocaleSelection = () => {
  const intl = useIntl()

  const activeLocale = intl.locale
  const inactiveLocale = activeLocale === "fi" ? "en" : "fi"

  return (
    <LocaleSelectionStyles>
      <button
        className="link-active"
        css={[LocaleOptionStyle]}
        style={{
          borderRadius: "50%",
          background: "rgba(252, 247, 215, 1)",
          color: "black",
          animation:
            "shadow-drop-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both",
        }}
        onClick={() => changeLocale(activeLocale)}
        type="button"
      >
        <LocaleKeyUpperCased>{activeLocale}</LocaleKeyUpperCased>
      </button>
      <button
        type="button"
        style={{ color: "white" }}
        css={[LocaleOptionStyle]}
        onClick={() => changeLocale(inactiveLocale)}
      >
        <LocaleKeyUpperCased>{inactiveLocale}</LocaleKeyUpperCased>
      </button>
    </LocaleSelectionStyles>
  )
}

export default LocaleSelection
