import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { SECONDARY_FONT } from "../util/constants"
import { useIntl, Link } from "gatsby-plugin-intl"

const MenuItemStyle = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  padding: 0.5rem 1rem 0.8rem 1rem;
`

const menuItemNameStyle = css`
  font-family: ${SECONDARY_FONT};
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1.1rem;
  margin-block-end: 0;
  margin-top: 0.2rem;
`

const linkStyle = {
  color: "white",
  textDecoration: "none",
}

const MenuItem = ({ to, id }) => {
  const intl = useIntl()

  return (
    <Link
      to={to}
      activeStyle={{ color: "black" }}
      activeClassName="link-active"
      style={linkStyle}
    >
      <MenuItemStyle>
        <p css={menuItemNameStyle}>{intl.formatMessage({ id })}</p>
      </MenuItemStyle>
    </Link>
  )
}

export default MenuItem
